import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
// import Call from '../components/Call';

class HallOfFame extends React.Component {
  render() {
    const { posts } = this.props.data.blog;
    return (
      <Layout bodyClass='page-blog'>
        <SEO title="Security Hall Of Fame" />
        <div className='container pb-6 pt-6 pt-md-10 pb-md-10'>
          <div className='row justify-content-start'>
            <div className='col-12 col-md-8'>
              <h1 className='title'>Security Hall Of Fame</h1>
              <p>
                We would like to thank these security researchers for
                their hard work in protecting our users' cyber security.
              </p>
              <p><li><a href="https://www.linkedin.com/in/seanpesce/"><font color="#e6720e">Sean Pesce</font></a></li></p>
              <p><li><a href="https://twitter.com/testanull"><font color="#e6720e">Beg Bounty Slayer</font></a></li></p>
              <div>
                {/* <div className='hof-group'>
                  <h2>2022</h2>
                  <div className='hof-list'>
                    <ul></ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query HOFQuery {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          author
        }
        excerpt
        id
        fields {
          slug
        }
      }
    }
  }
`;

export default HallOfFame;
